import React, {useEffect, useRef, useState, useImperativeHandle, forwardRef} from 'react';
import { useUserContext } from "../../contexts";
import CATEGORIES from './menu.json';

import './Menu.scss';

const Menu = ({visible, closeCallback, external}, ref) => {
    
    const {user} = useUserContext();

    const floatingMenuRef = useRef(null);
    const [showMenuContent, setShowMenuContent] = useState(false);
    const [floatingMenuExpand, setFloatingMenuExpand] = useState(null);

    const baseHref = external?'https://user.productioncrate.com':'';

    useEffect(() => {
        if(floatingMenuExpand === null || !visible || !floatingMenuRef || !floatingMenuRef.current){
            return;
        }
        console.log(floatingMenuExpand)
        window.scrollTo(0, 0);
        floatingMenuRef.current.scrollTo({ top: 0, behavior: 'smooth' });

    }, [floatingMenuExpand]);

    const replaceDomainsLoggedIn = (str, loggedIn) => {
        return !loggedIn?str:str.replace('rendercrate.com', 'productioncrate.com')
                        .replace('graphicscrate.com', 'graphics.productioncrate.com')
                        .replace('footagecrate.com', 'vfx.productioncrate.com')
                        .replace('soundscrate.com', 'sfx.productioncrate.com');
    };
    return (
        <>
        <div className="big_menu" ref={ref}>
            <div className="top_menu" >
                <div className="bg_layer"></div>
               {CATEGORIES.mainCategories.map((mainCategory, i) =>
                <div 
                    key={`key-main_category-${i}=${mainCategory.id}`} 
                    className={`menu_heading ${showMenuContent===mainCategory.id?'selected':''} `} 
                >
                    
                    <div className="title" data-alternate={mainCategory.alternate??mainCategory.name}><span>{mainCategory.name}</span></div> <span className="glyphicon glyphicon-down"></span>
                    <div className={`content_wrapper ${[0,1].includes(i)?'left-side':''} ${[CATEGORIES.mainCategories.length-1,CATEGORIES.mainCategories.length-2].includes(i)?'right-side':''}`} >
                        {mainCategory.categories.map((category, k) =>
                        <div 
                            className="menu" 
                            key={`mainCategory-${showMenuContent}-categories-${k}-${category.id}`}>
                            {category.link &&
                            <a className="title" href={replaceDomainsLoggedIn(category.link, user)}>
                                {category.name} 
                                <img src={`${baseHref}/images/icon.external_link.svg`} className="icon" />
                            </a>
                            }
                            {!category.link &&
                            <span className="title">
                                {category.name} 
                                <i className="glyphicon glyphicon-link"></i>
                            </span>
                            }
                            <div className={`submenu`}>
                                {category.subcategories?.map((subcategory, j) =>
                                <a href={replaceDomainsLoggedIn(subcategory.link, user)} className="submenuItem" key={`mainCategory-${showMenuContent}-categories-${i}-${category.id}-submenu-${j}`}>
                                    {subcategory.name}
                                    {subcategory.tag &&
                                    <span className="tag">{subcategory.tag}</span>
                                    }
                                </a>
                                )}
                            </div>
                        </div>
                        )}
                    </div>
                </div>
                )} 
            </div>        
        </div>
        <div className={`floating_menu ${visible?'visible':''}`} ref={floatingMenuRef}>
            <div className="barrier"></div>
            <span onClick={closeCallback} class={`close glyphicon glyphicon-cross`} ></span>
            {CATEGORIES.mainCategories.map((mainCategory, i) =>
                <>
                    <span className={`title ${floatingMenuExpand === i?'visible':''}`} onClick={() => setFloatingMenuExpand(prev => prev ===null || prev !== i?i:null)} >{mainCategory.name} <span className="glyphicon glyphicon-down"></span></span>
                    <div className={`list ${floatingMenuExpand === i?'visible':''}`}>
                    {mainCategory.categories.map((category, k) =>
                        <>
                        {category.link &&
                        <a className="subtitle" href={replaceDomainsLoggedIn(category.link, user)}> {category.name} <img src={`${baseHref}/images/icon.external_link.svg`} className="icon" /></a>
                        }
                        {!category.link &&
                        <span className="subtitle" > {category.name}</span>
                        }
                        {category.subcategories?.map((subcategory, j) =>
                            <a href={replaceDomainsLoggedIn(subcategory.link, user)} className="link" key={`mainCategory-${showMenuContent}-categories-${i}-${category.id}-submenu-${j}`}>
                                - {subcategory.name}
                                {subcategory.tag &&
                                <span className="tag">{subcategory.tag}</span>
                                }
                            </a>
                        )}
                        </>
                    )}
                    </div>
                </>
            )}
        </div>
        </>
    );
};


export default forwardRef(Menu);

